import React, { useEffect } from "react";
import { checkoutCart } from "../../redux/action/apiCart";
import {checkoutReset } from "../../redux/slices/cartSlice";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const CheckoutPage = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const { cartItems,checkoutSuccess } = useSelector((state) => state.cart);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('cartItems', checkoutSuccess);
    if(checkoutSuccess){
      navigate('/')
    }

  }, [checkoutSuccess]);
  useEffect(()=>{
   dispatch(checkoutReset())
  },[])
  const [formData, setFormData] = React.useState({
    email: "",
    fullName: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "Pakistan",
    paymentId: "COD",
    paymentMethod: "Cash on Delivery", // Default payment method
  });

  const [errors, setErrors] = React.useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePaymentMethodChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      paymentMethod: value,
      paymentId: "", // Clear payment ID when changing payment methods
    });
  };

  const validateForm = () => {
    let formErrors = {};
    
    // Validation for required fields
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }

    if (!formData.fullName) {
      formErrors.fullName = "Full name is required";
    }

    if (!formData.phone) {
      formErrors.phone = "Phone number is required";
    } else if (!/^\d{11}$/.test(formData.phone)) {
      formErrors.phone = "Phone number must be 11 digits";
    }

    if (!formData.address1) {
      formErrors.address1 = "Address line 1 is required";
    }

    if (!formData.city) {
      formErrors.city = "City is required";
    }

    if (!formData.state) {
      formErrors.state = "State is required";
    }

    if (!formData.zip) {
      formErrors.zip = "ZIP code is required";
    } else if (formData.zip.length !== 5) {
      formErrors.zip = "ZIP code must be 5 digits";
    }

    if (formData.paymentMethod === "Online Payment" && !formData.paymentId) {
      formErrors.paymentId = "Payment ID is required for online payments";
    }

    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      console.log("Form submitted successfully", formData);
  
      // Dispatch the checkoutCart action
       dispatch(checkoutCart(userInfo, formData, cartItems));
  
      // Perform navigation after successful dispatch
      // navigate("/order-summary");
    }
  };
  const calculateTotalPrice = () => {
    return cartItems.reduce((total, item) => {
      return total + item.price * item.qty;
    }, 0);
  };

  const totalPrice = calculateTotalPrice();

  return (
    <div className="flex justify-center items-center h-full bg-gray-100 py-10">
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="space-y-4">
          {cartItems?.map((val, index) => (
            <div key={index} className="grid grid-cols-2">
              <div>
                <h1 className="text-xl font-semibold">{val.name}</h1>
                <p className="text-2xl font-bold my-4">PKR {val.price}</p>
                <p className="text-lg font-bold my-4 text-gray-500">QTY {val.qty}</p>
              </div>
              <img src={val.image} alt={val.name} className="w-full rounded" />
            </div>
          ))}
        </div>

        <form className="space-y-4" onSubmit={handleSubmit}>
          <h2 className="text-xl font-semibold mb-4">Contact Information</h2>

          <div>
            <label className="block text-sm font-medium">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium">Phone Number</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
            />
            {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
          </div>

          <h2 className="text-xl font-semibold mb-4">Shipping Information</h2>

          <div>
            <label className="block text-sm font-medium">Full Name</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
            />
            {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium">Address Line 1</label>
            <input
              type="text"
              name="address1"
              value={formData.address1}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
            />
            {errors.address1 && <p className="text-red-500 text-sm mt-1">{errors.address1}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium">Address Line 2</label>
            <input
              type="text"
              name="address2"
              value={formData.address2}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
            />
          </div>

          <div>
            <label className="block text-sm font-medium">City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
            />
            {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium">State</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
            />
            {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium">ZIP</label>
            <input
              type="text"
              name="zip"
              value={formData.zip}
              onChange={handleInputChange}
              className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
            />
            {errors.zip && <p className="text-red-500 text-sm mt-1">{errors.zip}</p>}
          </div>

          <h2 className="text-xl font-semibold mb-4">Payment Method</h2>
          <div className="flex items-center mb-4">
            <input
              type="radio"
              name="paymentMethod"
              value="Cash on Delivery"
              checked={formData.paymentMethod === "Cash on Delivery"}
              onChange={handlePaymentMethodChange}
              className="mr-2"
            />
            <label>Cash on Delivery</label>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="radio"
              name="paymentMethod"
              value="Online Payment"
              checked={formData.paymentMethod === "Online Payment"}
              onChange={handlePaymentMethodChange}
              className="mr-2"
            />
            <label>Online Payment</label>
          </div>

          {formData.paymentMethod === "Online Payment" && (
          <div>
              {/* JaazzCash Account Details */}
          <h2 className="text-xl font-semibold mb-4">JaazzCash Payment Details</h2>
          <div className="bg-gray-100 p-4 rounded-md">
            <p><strong>Bank Name:</strong> JaazzCash</p>
            <p><strong>Account Name:</strong> Yume Atelier</p>
            <p><strong>Account Number:</strong> +923297476470</p>
            <p><strong>Till ID:</strong> 981197745</p>
            <p><strong>Amount:</strong> {totalPrice}</p>
            <p><strong>Note:</strong></p>
            <p> 1) Make payment directly in our account through any bank or wallet</p>
            <p> 2) Use Till ID if you are paying through jaazcash</p>
            <p> 3) Enter the reference number of the payment before placing an order</p>
          </div>

            <div>
              <label className="block text-sm font-medium">Payment ID</label>
              <input
                type="text"
                name="paymentId"
                placeholder="Enter TID or Reference No of receipt"
                value={formData.paymentId}
                onChange={handleInputChange}
                className="mt-1 block w-full border bg-white border-gray-300 rounded-md shadow-sm py-2 px-3"
              />
              {errors.paymentId && <p className="text-red-500 text-sm mt-1">{errors.paymentId}</p>}
            </div>
          </div>
          )}

          <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
          <div className="bg-gray-200 p-4 rounded-md">
            <p>Total Price: PKR {totalPrice}</p>
          </div>

          <button
            type="submit"
            className="w-full mt-6 bg-blue-600 text-white py-2 rounded-md"
          >
            Checkout
          </button>
        </form>
      </div>
    </div>
  );
};

export default CheckoutPage;
