import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    checkoutLoading: false,
    checkoutError: null,
    checkoutSuccess: false,
  },
  reducers: {
    cartAddItem: (state, action) => {
      const product = action.payload;
      const existItem = state.cartItems.find((prev) => product._id === prev._id);
      if (existItem) {
        state.cartItems = state.cartItems.map((prev) =>
          prev._id === product._id ? product : prev
        );
      } else {
        state.cartItems.push(product);
      }
    },
    cartDeleteItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (product) => product._id !== action.payload
      );
    },
    cartDeleteAll: (state) => {
      state.cartItems = [];
    },
    checkoutRequest: (state) => {
      state.checkoutLoading = true;
      state.checkoutError = null;
      state.checkoutSuccess = false;
    },
    checkoutSuccess: (state, action) => {
      state.checkoutLoading = false;
      state.checkoutSuccess = true;
    },
    checkoutFail: (state, action) => {
      state.checkoutLoading = false;
      state.checkoutError = action.payload;
    },
    checkoutReset: (state, action) => {
      state.checkoutLoading = false;
      state.checkoutError = null;
      state.checkoutSuccess = false;
    },
  },
});

export const {
  cartAddItem,
  cartDeleteItem,
  cartDeleteAll,
  checkoutRequest,
  checkoutSuccess,
  checkoutFail,
  checkoutReset
} = cartSlice.actions;

export default cartSlice.reducer;
