import React from "react";
import { addToCart, deleteFromCart } from "../../redux/action/apiCart.js";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Cart({ cartItems }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function deleteHandler(id) {
    dispatch(deleteFromCart(id));
  }

  function quantityHandler(product, count) {
    if (count > 0) {
      dispatch(
        addToCart(
          {
            ...product,
            images: [product.image],
          },
          count
        )
      );
    }
  }

  return (
    <div className="shoppingCart__container">
      {cartItems.length === 0 ? (
        <h1>Your shopping cart is currently empty.</h1>
      ) : (
        <div className="py-12">
          {cartItems.map((product) => {
            return (
              <div className="flex items-center space-x-2 mt-5" key={product._id}>
                <img
                  className="h-[200px]"
                  src={product.image}
                  alt="product_image"
                  onClick={() => navigate(`/product/${product._id}`)}
                />
                <h2
                  className=" cursor-pointer grow-1"
                  onClick={() => navigate(`/product/${product._id}`)}
                >
                  {product.name}
                </h2>
                <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-3 items-center lg:space-x-4 space-x-0">
                  <div className="addCount-button">
                    <img
                      src="https://d2c0vv5h4nuw6w.cloudfront.net/icons/minus-icon.png"
                      alt=""
                      onClick={(e) => quantityHandler(product, product.qty - 1)}
                    />
                    {product.qty}
                    <img
                      src="https://d2c0vv5h4nuw6w.cloudfront.net/icons/plus-icon.png"
                      alt=""
                      onClick={(e) => quantityHandler(product, product.qty + 1)}
                    />
                  </div>
                  <div className="flex lg:flex-row flex-col lg:space-x-4 space-x-0">


                <h2 className="">
                  PKR {product.price * product.qty}
                </h2>
                <h2
                  className="text-red-500 cursor-pointer"
                  onClick={() => deleteHandler(product._id)}
                >
                  Remove
                </h2>
                    </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
