
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p>
        At Yume Atelier, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website or make a purchase.
      </p>

      <h2 className="text-xl font-bold py-5">Information We Collect</h2>
      <h3>Personal Information</h3>
      <p>
        When you make a purchase or register on our site, we may collect the following personal information:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Mailing address</li>
        <li>Phone number</li>
        <li>Payment information (e.g., credit card details)</li>
      </ul>

      <h3>Non-Personal Information</h3>
      <p>
        We also collect non-personal information to improve your shopping experience:
      </p>
      <ul>
        <li>Browser type and version</li>
        <li>Operating system</li>
        <li>IP address</li>
        <li>Website usage data</li>
      </ul>

      <h2 className="text-xl font-bold py-5">How We Use Your Information</h2>
      <p>
        We use your personal information for various purposes, including:
      </p>
      <ul>
        <li>Processing Transactions: To process your orders and manage your account.</li>
        <li>Customer Service: To respond to your inquiries and provide customer support.</li>
        <li>Marketing: To send you promotional materials, special offers, and other information that may interest you. You can opt out of receiving these communications at any time.</li>
        <li>Improving Our Services: To analyze website usage and enhance our product offerings and website functionality.</li>
      </ul>

      <h2 className="text-xl font-bold py-5">Sharing Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:
      </p>
      <ul>
        <li>Service Providers: We may share your information with trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as these parties agree to keep your information confidential.</li>
        <li>Legal Requirements: We may release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect our or others' rights, property, or safety.</li>
      </ul>

      <h2 className="text-xl font-bold py-5">Protecting Your Information</h2>
      <p>
        We implement a variety of security measures to maintain the safety of your personal information:
      </p>
      <ul>
        <li>Secure Socket Layer (SSL) Technology: We use SSL encryption to protect sensitive information transmitted online.</li>
        <li>Data Encryption: We encrypt sensitive data stored on our servers.</li>
        <li>Access Controls: We limit access to personal information to employees, contractors, and agents who need that information to process it.</li>
      </ul>

      <h2 className="text-xl font-bold py-5">Your Choices</h2>
      <ul>
        <li>Access and Correction: You can access and update your personal information by logging into your account on our website.</li>
        <li>Opting Out: You can opt out of receiving marketing emails from us by following the unsubscribe instructions included in each email or contacting us directly.</li>
      </ul>

      <h2 className="text-xl font-bold py-5">Cookies and Tracking Technologies</h2>
      <p>
        Our website uses cookies and similar technologies to enhance your browsing experience:
      </p>
      <ul>
        <li>Cookies: Small files that a site or its service provider transfers to your computer’s hard drive through your web browser (if you allow) that enable the site’s or service provider’s systems to recognize your browser and capture and remember certain information.</li>
        <li>Analytics: We may use third-party service providers to monitor and analyze the use of our website.</li>
      </ul>

      <h2 className="text-xl font-bold py-5">Changes to Our Privacy Policy</h2>
      <p>
        We reserve the right to update this Privacy Policy at any time. If we make material changes, we will notify you by email or by posting a notice on our website. Your continued use of our website after such changes signifies your acceptance of the updated policy.
      </p>

      <h2 className="text-xl font-bold py-5">Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at:
        <br />
        Instagram & Facebook: yumeatelier.pk
        <br />
        Email: theyumeatelier@gmail.com
        <br />
        Monday to Saturday (12:00 pm to 08:00 pm)
      </p>
      
      <p>
        Thank you for trusting Yume Atelier with your personal information. We are committed to safeguarding your privacy and ensuring a secure online experience.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
