import React from "react";
import HeaderLeftMenu from "./HeaderLeftMenu";
import HeaderRightMenu from "./HeaderRightMenu";

export default function Header() {
  return (
    <div>
      <div className="flex items-center justify-center space-x-4 py-2 bg-black text-white shadow-lg">
  
        <div>
          <p className="font-bold text-sm">FREE Shipping All over Pakistan</p>
        </div>
      </div>
      <div className="header">

        <HeaderLeftMenu />
        <HeaderRightMenu />
      </div>
    </div>
  );
}
