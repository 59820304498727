import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import './index.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer 
      position="bottom-center" // Set position here
      autoClose={5000} // Optional: Auto close after 5 seconds
      hideProgressBar={false} // Optional: Show progress bar
      newestOnTop={false} // Optional: Show newest on top
      closeOnClick // Optional: Allow closing on click
      pauseOnHover // Optional: Pause on hover
      draggable // Optional: Allow dragging
      pauseOnFocusLoss // Optional: Pause when the user is focused elsewhere
    />
  </Provider>
);
