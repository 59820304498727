import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Autoplay } from "swiper/modules"; // Correct import for Autoplay module

import Ratings from "../Ratings";
import { useNavigate } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/autoplay'; // Make sure to import autoplay CSS

export default function ProductsPrimary({ product, autoplay = false }) { // Add autoplay prop with default value
  const navigate = useNavigate();

  useEffect(() => {
    console.log('product images:', product.images);
  }, [product.images]);

  return (
    <div className="w-full h-auto cursor-pointer" onClick={() => navigate(`/product/${product._id}`)}> {/* Fixed size for container */}
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={autoplay ? { // Conditionally set autoplay
          delay: 3000,
          disableOnInteraction: false, // Ensures autoplay doesn't stop after interaction
        } : false}
        modules={[autoplay ? Autoplay : null].filter(Boolean)} // Register the Autoplay module only if autoplay is true
        className="w-full lg:h-[300px] h-[230px]"  // Ensure Swiper takes full width and height of the container
      >
        {product.images.map((image, index) => (
          <SwiperSlide key={index}>
            <LazyLoadImage
              src={image}
              alt={`product_image_${index}`}
              className="w-full h-full object-cover"  // Ensures image fits the SwiperSlide
              effect="blur"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <h1 className="pt-3 text-lg font-semibold cursor-pointer">{product.name}</h1>
      {/* <Ratings product={product} /> */}
      <h2>PKR {product.price}</h2>
      {product.isNewArrival && (
        <div className="productCard__isNewTag">New!</div>
      )}
    </div>
  );
}
