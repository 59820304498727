import React from "react";
import { useSearchParams } from "react-router-dom";
import { ListItemButton, ListItemText, Collapse, List } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import FilterCheckBox from "./FilterCheckBox";

const ListItemstyle = {
  borderBottom: "1px solid #CCCCCC",
  paddingTop: "20px",
  paddingBottom: "20px",
};

export default function Category() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCategory, setOpenCategory] = React.useState(false);
  const [category, setCategory] = React.useState({
    Pants: false,
    Shirts: false,
  });

  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      const params = searchParams.get("category");
      if (params && category[params] !== undefined) {
        setCategory((prev) => ({
          Pants: params === "Pants",
          Shirts: params === "Shirts",
        }));
      }
      return;
    }

    const selectedCategory = Object.keys(category).find(
      (key) => category[key]
    );

    if (!selectedCategory) {
      searchParams.delete("category");
    } else {
      searchParams.set("category", selectedCategory);
    }
    setSearchParams(searchParams);
  }, [category, searchParams, setSearchParams]);

  const handleCategoryChange = (selected) => {
    setCategory((prev) => ({
      Pants: selected === "Pants" ? !prev.Pants : false,
      Shirts: selected === "Shirts" ? !prev.Shirts : false,
    }));
  };

  return (
    <>
      <ListItemButton
        style={ListItemstyle}
        onClick={() => setOpenCategory(!openCategory)}
      >
        <ListItemText
          disableTypography
          sx={{
            fontSize: "1.1rem",
            fontWeight: "600",
          }}
          primary="Category"
        />
        {openCategory ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openCategory} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* Pants */}
          <ListItemButton
            onClick={() => handleCategoryChange("Pants")}
          >
            <FilterCheckBox label="Pants" checked={category.Pants} />
          </ListItemButton>

          {/* Shirts */}
          <ListItemButton
            onClick={() => handleCategoryChange("Shirts")}
          >
            <FilterCheckBox label="Shirts" checked={category.Shirts} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
}
