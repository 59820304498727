import React from "react";
import { useNavigate } from "react-router-dom";
import Insta from "../../assests/insta.svg"
export default function Footer() {
  const navigate = useNavigate();
  return (
    <ul className="flex lg:flex-row flex-col lg:space-y-0 space-y-4  py-4 mt-12 lg:justify-end justify-start lg:px-12 px-0 lg:space-x-10 space-x-0 items-center">
      <a href="https://www.instagram.com/yumeatelier.pk?igsh=azdleDF3NmM4N3Ni"><img src={Insta} alt="some" className="w-10"/></a>
      <li onClick={() => navigate("/legal")}>Terms of use</li>
      <li onClick={() => navigate("/privacy")}>Privacy</li>
      <li onClick={() => navigate("/refund-policy")}>Refund Policy</li>
      <li onClick={() => navigate("/shipping-policy")}>Shipping Policy</li>
      <li onClick={() => navigate("/contact")}>Contact</li>
      <li>Copyright © Yume-Atelier</li>
    </ul>
  );
}
