import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../redux/action/apiUserAction";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import DisplayPending from "../../components/DisplayPending";
import Alert from "@mui/material/Alert";
import CustomDivider from "../../components/CustomDivider";

export default function ChangePasswordPage() {
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.user);
  const { pending, error, errorMessage, isPasswordChanged } = userLogin;

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  // get query param
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") ? searchParams.get("redirect") : "";

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (isPasswordChanged) {
      navigate(`/login`);
    }
  }, [navigate, isPasswordChanged, redirect]);

  function handleLogin(e) {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setPasswordError(""); // Clear error if passwords match
    dispatch(changePassword(email,otp, password));
  }

  return (
    <div className="auth">
      <Meta title="Login" />
      <DisplayPending pending={pending} />
      <form className="auth__container" onSubmit={handleLogin}>
        {error && <Alert severity="error">{errorMessage}</Alert>}
        <div className="auth__title">Change Password</div>
        <div className="auth__input__container">
          <label htmlFor="email">Email</label>
          <input
            className="!bg-white"
            id="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="auth__input__container">
          <label htmlFor="password">Password</label>
          <input
            className="!bg-white"
            id="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="auth__input__container">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            className="!bg-white"
            id="confirmPassword"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {passwordError && <Alert severity="error">{passwordError}</Alert>}
        <div className="auth__input__container">
          <label htmlFor="otp">Otp</label>
          <input
            className="!bg-white"
            id="otp"
            type="text"
            onChange={(e) => setOtp(e.target.value)}
            required
          />
        </div>
        <button className="auth-button" type="submit">
          Change Password
        </button>
        <CustomDivider text={"OR"} />
        <h1 className="auth__navigation">
          Need an account?{" "}
          <span onClick={(e) => navigate(`/register?redirect=${redirect}`)}>Register</span>
        </h1>
        <h1 className="auth__legal">
          By Logging into QuickChat you agree to our{" "}
          <span className="color-blue-600 cursor-pointer" onClick={() => navigate("/legal")}>
            Terms of Services
          </span>{" "}
          and{" "}
          <span className="color-blue-600 cursor-pointer" onClick={() => navigate("/privacy")}>
            Privacy Policy.
          </span>
        </h1>
      </form>
    </div>
  );
}
