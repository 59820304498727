// import React from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { logout } from "../../redux/action/apiUserAction";
// import { adminGetProduct, adminUpdateProduct } from "../../lib/axiosAPI";
// import DisplayPending from "../../components/DisplayPending";
// import Alert from "@mui/material/Alert";

// export default function AdminEditProduct() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const params = useParams();
//   let { userInfo } = useSelector((state) => state.user);

//   const [pending, setPending] = React.useState(true);
//   const [error, setError] = React.useState();

//   const [img, setImg] = React.useState([]);
//   const [name, setName] = React.useState("");
//   const [description, setDescription] = React.useState("");
//   const [category, setCategory] = React.useState("");
//   const [brand, setBrand] = React.useState("");
//   const [price, setPrice] = React.useState("");
//   const [stock, setStock] = React.useState("");
//   const [newArrivals, setNewArrivals] = React.useState(false);
//   const [isRecommend, setIsRecommend] = React.useState(false);
//   const [createSuccess, setCreateSuccess] = React.useState(false);

//   React.useEffect(() => {
//     adminGetProduct(userInfo, params.id)
//       .then(function (res) {
//         const data = res.data;
//         setImg(data.images);
//         setName(data.name);
//         setDescription(data.description);
//         setCategory(data.category);
//         setBrand(data.brand);
//         setPrice(data.price);
//         setStock(data.countInStock);
//         setIsRecommend(data.isRecommend);
//         setNewArrivals(data.isNewArrival);
//         setPending(false);
//       })
//       .catch(function (error) {
//         setPending(false);
//         if (error.response.status === 401) {
//           dispatch(logout());
//         } else {
//           setError(error.response.data.message);
//         }
//       });
//   }, [dispatch, params.id, userInfo]);

//   const updateProductHandler = async (e) => {
//     e.preventDefault();
//     window.scrollTo(0, 0);
//     setPending(true);
//     const formData = new FormData();

//     for (let i = 0; i < img.length; i++) {
//       formData.append("images", img[i]);
//     }

//     formData.append("name", name);
//     formData.append("description", description);
//     formData.append("category", category);
//     formData.append("brand", brand);
//     formData.append("price", price);
//     formData.append("countInStock", stock);
//     formData.append("newArrivals", newArrivals);
//     formData.append("isRecommend", isRecommend);

//     adminUpdateProduct(userInfo, params.id, formData)
//       .then(function (res) {
//         setCreateSuccess(true);
//         setPending(false);
//       })
//       .catch(function (error) {
//         setPending(false);
//         if (error.response.status === 401) {
//           dispatch(logout());
//         } else {
//           setError(error.response.data.message);
//         }
//       });
//   };

//   return (
//     <div className="auth">
//       <DisplayPending pending={pending} />
//       <form
//         className="adminCreateProduct__container"
//         onSubmit={updateProductHandler}
//       >
//         {createSuccess ? (
//           <Alert severity="success">Update Succeed</Alert>
//         ) : error ? (
//           <Alert severity="error">{error}</Alert>
//         ) : null}
//         <div className="auth__title">Edit Product</div>

//         <div className="auth__input__container">
//           <label htmlFor="update_name">Name: </label>
//           <input
//             id="update_name"
//             type="text"
//             onChange={(e) => setName(e.target.value)}
//             value={name}
//             required
//           />
//         </div>
//         <div className="auth__input__container">
//           <label htmlFor="update_description">Description: </label>
//           <textarea
//             id="update_description"
//             className="hide-scrollbar"
//             onChange={(e) => setDescription(e.target.value)}
//             value={description}
//             required
//           ></textarea>
//         </div>
//         <div className="auth__input__container">
//           <label>Category: </label>
//           <input
//             id="update_category"
//             type="text"
//             onChange={(e) => setCategory(e.target.value)}
//             value={category}
//             required
//           />
//         </div>
//         <div className="auth__input__container">
//           <label htmlFor="update_brand">Brand: </label>
//           <input
//             id="update_brand"
//             type="text"
//             onChange={(e) => setBrand(e.target.value)}
//             value={brand}
//             required
//           />
//         </div>
//         <div className="auth__input__container">
//           <label htmlFor="update_price">Price (In Cent): </label>
//           <input
//             id="update_price"
//             type="number"
//             onChange={(e) => setPrice(e.target.value)}
//             value={price}
//             min="0"
//             required
//           />
//         </div>
//         <div className="auth__input__container">
//           <label htmlFor="update_stock">Count In Stock: </label>
//           <input
//             id="update_stock"
//             type="number"
//             onChange={(e) => setStock(e.target.value)}
//             value={stock}
//             min="0"
//             required
//           />
//         </div>

//         <div className="auth__checkbox">
//           <input
//             id="update_newArrivals"
//             type="checkbox"
//             checked={newArrivals}
//             onChange={(e) => setNewArrivals(!newArrivals)}
//           />
//           <label htmlFor="update_newArrivals">New Arrivals?</label>
//         </div>
//         <div className="auth__checkbox">
//           <input
//             id="update_recommend"
//             type="checkbox"
//             checked={isRecommend}
//             onChange={(e) => setIsRecommend(!isRecommend)}
//           />
//           <label htmlFor="update_recommend">Recommend?</label>
//         </div>

//         <div className="product-image-upload__container">
//           <label htmlFor="product-image-upload">Image: </label>
//           <input
//             id="product-image-upload"
//             style={{ border: "none ", borderRadius: "0" }}
//             type="file"
//             accept="image/*"
//             multiple
//             onChange={(e) => setImg(e.target.files)}
//           />
//         </div>

//         <button type="submit" className="auth-button green-button">
//           Update
//         </button>
//         <button
//           className="auth-button"
//           type="submit"
//           onClick={() => navigate("/admin/productlist")}
//         >
//           Go Back
//         </button>
//       </form>
//     </div>
//   );
// }
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/action/apiUserAction";
import { adminGetProduct, adminUpdateProduct } from "../../lib/axiosAPI";
import DisplayPending from "../../components/DisplayPending";
import Alert from "@mui/material/Alert";
import { useDropzone } from "react-dropzone";

export default function AdminEditProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.user);

  const [images, setImages] = React.useState([]);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [stock, setStock] = React.useState("");
  const [size, setSize] = React.useState(""); // Size input
  const [measurements, setMeasurements] = React.useState(""); // Measurements input
  const [newArrivals, setNewArrivals] = React.useState(false);
  const [isRecommend, setIsRecommend] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [error, setError] = React.useState();
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [sizes, setSizes] = React.useState([{ size: "", quantity: "", price: "", measurements: [{ name: "", value: "" }] }]);
  // Fetch existing product details
  React.useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await adminGetProduct(userInfo, id);
        const data = res.data;
        setImages(data.images);
        setName(data.name);
        setDescription(data.description);
        setCategory(data.category);
        setSubCategory(data.subCategory);
        setBrand(data.brand);
        setPrice(data.price);
        setStock(data.countInStock);
        setSizes(data.sizes || ""); // Set size
        setMeasurements(data.measurements || ""); // Set measurements
        setNewArrivals(data.isNewArrival);
        setIsRecommend(data.isRecommend);
        setPending(false);
      } catch (error) {
        setPending(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(error.response.data.message);
        }
      }
    };

    fetchProduct();
  }, [dispatch, id, userInfo]);

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const handleSizeChange = (index, field, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[index][field] = value;
    setSizes(updatedSizes);
  };

  const addSizeField = () => {
    setSizes([...sizes, { size: "", quantity: "", price: "", measurements: [{ name: "", value: "" }] }]);
  };

  const removeSizeField = (index) => {
    const updatedSizes = sizes.filter((_, i) => i !== index);
    setSizes(updatedSizes);
  };

  const handleMeasurementChange = (sizeIndex, measurementIndex, field, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].measurements[measurementIndex][field] = value;
    setSizes(updatedSizes);
  };

  const addMeasurementField = (sizeIndex) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].measurements.push({ name: "", value: "" });
    setSizes(updatedSizes);
  };

  const removeMeasurementField = (sizeIndex, measurementIndex) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].measurements = updatedSizes[sizeIndex].measurements.filter((_, i) => i !== measurementIndex);
    setSizes(updatedSizes);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setPending(true);

    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image);
    });
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("subCategory", subCategory);
    formData.append("brand", brand);
    formData.append("price", price);
    formData.append("countInStock", stock);
    formData.append("sizes", JSON.stringify(sizes)); // Include size
    formData.append("measurements", measurements); // Include measurements
    formData.append("newArrivals", newArrivals);
    formData.append("isRecommend", isRecommend);

    try {
      await adminUpdateProduct(userInfo, id, formData);
      setUpdateSuccess(true);
      setPending(false);
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        dispatch(logout());
      } else {
        setError(error.response.data.message);
      }
      setUpdateSuccess(false);
    }
  };

  return (
    <div className="p-6 max-w-3xl mx-auto bg-white rounded-lg shadow-md">
      <DisplayPending pending={pending} />
      <form className="space-y-4" onSubmit={submitHandler}>
        {updateSuccess ? (
          <Alert severity="success">Update Succeeded</Alert>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : null}
        <h2 className="text-2xl font-semibold text-center mb-4">Edit Product</h2>

        <div>
          <label className="block text-sm font-medium">Name</label>
          <input
            type="text"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Description</label>
          <textarea
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Category</label>
          <input
            type="text"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Sub-Category</label>
          <input
            type="text"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setSubCategory(e.target.value)}
            value={subCategory}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Brand</label>
          <input
            type="text"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setBrand(e.target.value)}
            value={brand}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Price (In Cent)</label>
          <input
            type="number"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
            min="0"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Count In Stock</label>
          <input
            type="number"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setStock(e.target.value)}
            value={stock}
            min="0"
            required
          />
        </div>



        <div className="auth__checkbox">
          <input
            id="update_newArrivals"
            type="checkbox"
            checked={newArrivals}
            onChange={(e) => setNewArrivals(e.target.checked)}
          />
          <label htmlFor="update_newArrivals">New Arrivals?</label>
        </div>
        <div className="auth__checkbox">
          <input
            id="update_recommend"
            type="checkbox"
            checked={isRecommend}
            onChange={(e) => setIsRecommend(e.target.checked)}
          />
          <label htmlFor="update_recommend">Recommend?</label>
        </div>

        <div {...getRootProps()} className="border-2 border-dashed p-4">
          <input {...getInputProps()} />
          <p>Drag & drop some files here, or click to select files</p>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {images.map((file) => (
              <div key={file.name} className="relative">
                <img
                  src={file}
                  alt="preview"
                  className="h-20 w-20 object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() =>
                    setImages(images.filter((img) => img.name !== file.name))
                  }
                  className="absolute top-0 right-0 bg-red-600 w-8 h-8 text-white rounded-full p-1 text-sm"
                >
                  x
                </button>
              </div>
            ))}
          </div>
        </div>
        {/* Sizes and Measurements */}
        <div className="space-y-4">
          {sizes.map((sizeObj, index) => (
            <div key={index} className="space-y-2">
              <div className="flex space-x-2 flex-wrap">
                <input
                  type="text"
                  placeholder="Size"
                  className="flex-1 p-2 border rounded-md bg-white"
                  onChange={(e) => handleSizeChange(index, "size", e.target.value)}
                  value={sizeObj.size}
                  required
                />
                <input
                  type="number"
                  placeholder="Quantity"
                  className="flex-1 p-2 border rounded-md bg-white"
                  onChange={(e) => handleSizeChange(index, "quantity", e.target.value)}
                  value={sizeObj.quantity}
                  min="0"
                  required
                />
                <input
                  type="number"
                  placeholder="Price"
                  className="flex-1 p-2 border rounded-md bg-white"
                  onChange={(e) => handleSizeChange(index, "price", e.target.value)}
                  value={sizeObj.price}
                  min="0"
                  required
                />
                <button
                  type="button"
                  className="text-white bg-red-400 px-4 rounded-lg py-2 mt-3"
                  onClick={() => removeSizeField(index)}
                >
                  Remove Size
                </button>
              </div>
              {sizeObj.measurements.map((measurement, measurementIndex) => (
                <div key={measurementIndex} className="flex flex-wrap space-x-2">
                  <input
                    type="text"
                    placeholder="Measurement Name"
                    className="flex-1 p-2 border rounded-md bg-white"
                    onChange={(e) =>
                      handleMeasurementChange(index, measurementIndex, "name", e.target.value)
                    }
                    value={measurement.name}
                  />
                  <input
                    type="text"
                    placeholder="Measurement Value"
                    className="flex-1 p-2 border rounded-md bg-white"
                    onChange={(e) =>
                      handleMeasurementChange(index, measurementIndex, "value", e.target.value)
                    }
                    value={measurement.value}
                  />
                  <button
                    type="button"
                    className="text-white bg-red-400 px-4 rounded-lg "
                    onClick={() => removeMeasurementField(index, measurementIndex)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="text-blue-500 border border-blue-500 px-4 py-2 rounded-lg mt-2"
                onClick={() => addMeasurementField(index)}
              >
                Add Measurement
              </button>
            </div>
          ))}
          <button
            type="button"
            className="text-blue-500 border border-blue-500  px-4 py-2 rounded-lg"
            onClick={addSizeField}
          >
            Add Size
          </button>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
        >
          Update Product
        </button>
      </form>
    </div>
  );
}
