import {
  cartAddItem,
  cartDeleteItem,
  cartDeleteAll,

} from "../slices/cartSlice.js";
import axios from "axios";
import { checkoutRequest, checkoutSuccess, checkoutFail,checkoutReset } from "../slices/cartSlice";
import { toast } from "react-toastify";

export const checkoutCart = (userInfo, formData, cartItems) => {
  return async (dispatch, getState) => {
    console.log('soe',cartItems)
    toast.success("In Process!");
    if(!cartItems || cartItems.lenght===0){
      toast.error("Not Items Found");
    }
    else{
      try {
        // Dispatch checkout request action
        dispatch(checkoutRequest());
  
        const config = {
          headers: {
            Bearer: `${userInfo?.token}`,
            "Content-Type": "application/json",
          },
        };
        const calculateTotalPrice = () => {
          return cartItems.reduce((total, item) => {
            return total + item.price * item.qty; // Assuming `item.price` is the price of each item and `item.qty` is the quantity
          }, 0);
        };
        const totalPrice = calculateTotalPrice();
        console.log(formData)
        const payload = {
          orderItems: cartItems,
          shippingAddress: {
            line1: formData?.address1, // Should not be null or undefined
            line2: formData?.address2 || '', // Provide a default value if undefined
            city: formData.city, // Ensure this is not undefined
            postalCode: formData.zip, // Ensure this is not undefined
            country: formData.country // Ensure this is not undefined
          },
          phone: formData?.phone, // Ensure this is not undefined
          paymentMethod: formData.paymentMethod, // Ensure this is not undefined
          itemsPrice: totalPrice,
          taxPrice: totalPrice,
          shippingPrice: totalPrice,
          totalPrice: totalPrice,
          orderPaymentID: formData?.paymentId // Ensure this is not undefined
        };
        console.log('payload', payload)
        // Make API call to checkout endpoint
        const { data } = await axios.post("/api/orders",
          payload,
          config);
  
        // Dispatch checkout success and save order data
        dispatch(checkoutSuccess(data));
        toast.success("Order placed successfully!");
        // Clear cart after successful checkout
        dispatch(cartDeleteAll());
  
        // Update cart in localStorage
        localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  
      } catch (error) {
        // Dispatch checkout failure
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
        dispatch(
          checkoutFail(
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
      dispatch(cartDeleteAll());
    }
  };
};
export const addToCart = (product, qty) => {
  return (dispatch, getState) => {
    dispatch(
      cartAddItem({
        _id: product._id,
        name: product.name,
        image: product.images[0],
        price: product.price,
        qty: qty,
      })
    );
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };
};

export const deleteFromCart = (id) => {
  return (dispatch, getState) => {
    dispatch(cartDeleteItem(id));
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };
};

export const clearCart = () => {
  return (dispatch) => {
    dispatch(cartDeleteAll());
    localStorage.setItem("cartItems", []);
  };
};
