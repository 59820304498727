import React from 'react';

const ShippingPolicy = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Shipping Policy</h1>
      <p className="mb-4">
        At Yume Atelier, we aim to provide you with the best shopping experience, including reliable and timely delivery of your orders. Please review our shipping policy below for detailed information on our shipping practices and procedures.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Domestic Shipping</h2>

      <h3 className="text-xl font-semibold mb-2">Shipping Rates and Delivery Times</h3>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Standard Shipping:</strong></li>
        <ul className="pl-5 mb-4">
          <li><strong>Cost:</strong> Free</li>
          <li><strong>Delivery Time:</strong> 3-5 business days</li>
        </ul>
      </ul>
      <p className="mb-4">Please note that delivery times are estimates and may vary based on your location and other factors outside our control.</p>

      <h3 className="text-xl font-semibold mb-2">Order Processing</h3>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Processing Time:</strong> All orders are processed within 2-3 business days. Orders are not shipped or delivered on weekends or holidays.</li>
        <li><strong>Order Confirmation:</strong> You will receive an order confirmation email once your order has been placed. A second email with tracking information will be sent once your order has shipped.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Tracking Your Order</h3>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Tracking Information:</strong> Once your order has shipped, you will receive a tracking number via email. You can track your shipment through the carrier's website or through our order tracking page <a href="#" className="text-blue-600">[Insert Tracking Page Link]</a>.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Shipping Restrictions</h3>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>PO Boxes:</strong> We do not ship to PO Boxes. Please provide a physical address for delivery.</li>
        <li><strong>Remote Areas:</strong> Shipping to remote or rural areas may take longer than the standard delivery times.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Delays and Issues</h3>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Weather Delays:</strong> Please note that severe weather conditions can cause shipping delays. We will do our best to inform you of any delays affecting your order.</li>
        <li><strong>Lost or Stolen Packages:</strong> Yume Atelier is not responsible for lost or stolen packages. If your tracking information states that your package was delivered but you have not received it, please contact the carrier for assistance.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Changes to Your Order</h3>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Address Changes:</strong> If you need to change the shipping address after placing your order, please contact us at the email or Instagram provided below. We will do our best to accommodate your request, but please note that we cannot make changes once the order has been shipped.</li>
        <li><strong>Order Cancellations:</strong> To cancel your order, please contact us within 2-3 hours of placing your order. Once an order has been processed, it cannot be canceled.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
      <p className="mb-4">
        If you have any questions or concerns about our shipping policy, please contact us at:
      </p>
      <ul className="list-disc pl-5">
        <li><strong>Instagram & Facebook:</strong> <a href="https://www.instagram.com/yumeatelier.pk" className="text-blue-600">yumeatelier.pk</a></li>
        <li><strong>Email:</strong> <a href="mailto:theyumeatelier@gmail.com" className="text-blue-600">theyumeatelier@gmail.com</a></li>
      </ul>
      <p className="mt-4">
        <strong>Hours:</strong> Monday to Saturday (12:00 PM to 08:00 PM)
      </p>

      <p className="mt-4">
        Thank you for shopping with Yume Atelier. We appreciate your business and are committed to providing you with exceptional service and quality products.
      </p>
    </div>
  );
};

export default ShippingPolicy;
