import React from 'react';

const ContactInformation = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Contact Information</h1>
      <p className="mb-4">
        At Yume Atelier, we value our customers and are here to assist you with any questions or concerns. Please feel free to reach out to us using the contact information below. We look forward to hearing from you!
      </p>

      <h2 className="text-2xl font-semibold mb-2">Customer Service</h2>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Instagram & Facebook:</strong> <a href="https://www.instagram.com/yumeatelier.pk" className="text-blue-600">yumeatelier.pk</a></li>
        <li><strong>Email:</strong> <a href="mailto:theyumeatelier@gmail.com" className="text-blue-600">theyumeatelier@gmail.com</a></li>
        <li><strong>Available:</strong> Monday to Saturday (12:00 PM to 08:00 PM)</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Social Media</h2>
      <p className="mb-4">Connect with us on social media for the latest updates, promotions, and more:</p>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Facebook:</strong> <a href="https://www.facebook.com/yumeatelier.pk" className="text-blue-600">yumeatelier.pk</a></li>
        <li><strong>Instagram:</strong> <a href="https://www.instagram.com/yumeatelier.pk" className="text-blue-600">yumeatelier.pk</a></li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Frequently Asked Questions (FAQ)</h2>
      <p className="mb-4">
        For immediate assistance, please check our <a href="#" className="text-blue-600">FAQ page</a> where we address common questions about orders, shipping, returns, and more.
      </p>

      <p className="mt-4">
        Thank you for choosing Yume Atelier. We appreciate your business and are dedicated to providing you with exceptional service and quality products.
      </p>
    </div>
  );
};

export default ContactInformation;
