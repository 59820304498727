import React from "react";
import Img6 from "../../assests/img6.JPG";
import Img4 from "../../assests/img4.png";
import { useNavigate } from 'react-router-dom';

const OurCreations = () => {
  const navigate = useNavigate();

  const handleSearch = (val) => {
    navigate(`/shop?query=${val}`);
  };

  return (
    <section className="py-10 bg-gray-100">
      <div className="max-w-screen-xl mx-auto px-6">
        <div className="mb-8 text-center">
          <h2 className="text-3xl font-bold text-black">Our Creations</h2>
          <p className="text-gray-600 mt-4">
            This is an example of our creations, we are very happy to be able to
            make something like this for you too
          </p>
          <button className="mt-4 text-gray-600 flex items-center justify-center mx-auto">
            Read More <span className="ml-2">→</span>
          </button>
        </div>

        {/* Responsive Flexbox grid */}
        <div className="flex justify-center">
        <div  className="lg:w-2/3 w-full  grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 justify-center">
          <div onClick={()=>handleSearch('men')} className="bg-white cursor-pointer rounded-lg shadow-md relative ">
            <img
              src={Img6}
              alt="Exclusive Pent Collection"
              className="w-full h-[700px] object-top rounded-lg object-cover" // Responsive image styling
            />
          <div className="absolute top-0 h-full flex flex-col w-full space-y-6  items-center justify-center">
            <h3 className="text-6xl font-semibold text-black px-4 mt-4">Men</h3>
            <div className="flex justify-center ">

              <button onClick={()=>handleSearch('men')} className="text-white px-4 rounded-full bg-black flex items-center mx-4 py-4">
                Shop Now <span className="ml-2">→</span>
              </button>
            </div>
            </div>
          </div>

          <div onClick={()=>handleSearch('women')} className="bg-white cursor-pointer rounded-lg shadow-md  relative">
            <img
              src={Img4}
              alt="Exclusive Shirt Collection"
              className="w-full h-[700px] object-top rounded-lg object-cover" // Responsive image styling
            />
            <div className="absolute top-0 h-full flex flex-col w-full space-y-6  items-center justify-center">
            <h3 className="text-6xl font-semibold text-black px-4 mt-4">Women</h3>
            <div className="flex justify-center ">
              <button onClick={()=>handleSearch('women')} className="text-white px-4 rounded-full bg-black flex items-center mx-4 py-4">
                Shop Now <span className="ml-2">→</span>
              </button>
            </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default OurCreations;
