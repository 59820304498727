import React, { useEffect } from "react";
import Filter from "./components/Filter";
import Sort from "./components/Sort";
import Listing from "./components/Listing";
import { useSearchParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Paginate from "../../components/Paginate/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getProductList } from "../../redux/action/apiProductList.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Img6 from "../../assests/img6.png"
import Img7 from "../../assests/man.jpg"
import FilterIcon from "../../assests/filter.svg"
export default function AllProducts() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search");
  const query = searchParams.get("query");
  const currPageQuery = searchParams.get("currPage");
  const sortQuery = searchParams.get("sortBy");
  const inStockQuery = searchParams.get("inStock");
  const categoryQuery = searchParams.get("category");
  const brandQuery = searchParams.get("brand");
  const minPriceQuery = searchParams.get("minPrice");
  const maxPriceQuery = searchParams.get("maxPrice");
  const { productListInfo } = productList;

  React.useEffect(() => {
    getProductList(
      dispatch,
      searchQuery,
      currPageQuery,
      sortQuery,
      inStockQuery,
      query,
      categoryQuery,
      brandQuery,
      minPriceQuery,
      maxPriceQuery
    );
  }, [
    dispatch,
    searchQuery,
    currPageQuery,
    sortQuery,
    inStockQuery,
    query,
    categoryQuery,
    brandQuery,
    minPriceQuery,
    maxPriceQuery,
  ]);
  useEffect(() => {
    console.log('searchParams', query)
  }, [query])
  return (
    <div className="">
      {searchQuery ? (
        <div className="allproducts__title__search">
          <Meta title={searchQuery} />
          <div>Show Search Result:</div>
          <div>{searchQuery}</div>
        </div>
      ) : <div>
      </div>}
      <Sort />
     <div className="lg:hidden flex justify-between items-center pb-6 w-full px-10">
      <h1 className="w-full">
        Filters
      </h1>
     <div className="drawer  w-12">
        <input id="filter-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          {/* Page content here */}
          <label htmlFor="filter-drawer" className=" bg-white drawer-button"><img src={FilterIcon} alt="icon" className="w-6"/></label>
        </div>
        <div className="drawer-side z-[99999]">
          <label htmlFor="filter-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
          <ul className="menu bg-white text-black min-h-full w-80 p-4">
          <Filter />
          </ul>
        </div>
      </div>
     </div>
      <div className="grid grid-cols-4 gap-10">
        <div className="col-span-1 border-r border-gray-500 lg:block hidden">
          <Filter />
        </div>
        <div className="lg:col-span-3 col-span-4">
          <Listing products={productListInfo.products} />
        </div>
      </div>
      <Paginate page={productListInfo.page} pages={productListInfo.pages} />
    </div>
  );
}
