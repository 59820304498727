import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Refund Policy</h1>
      <p className="mb-4">
        At Yume Atelier, we are committed to providing you with the highest quality products and ensuring your satisfaction. If you are not completely satisfied with your purchase, we are here to help with a straightforward and hassle-free refund policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Eligibility for Refunds</h2>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Time Frame:</strong> You have <strong>2-3 days</strong> from the date of purchase to request a refund.</li>
        <li><strong>Condition:</strong> Items must be <strong>unused</strong>, <strong>unwashed</strong>, and in the <strong>same condition</strong> that you received them. They must also be in the <strong>original packaging</strong> with all tags attached.</li>
        <li><strong>Proof of Purchase:</strong> A <strong>receipt</strong> or proof of purchase is required to process your refund.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Non-Refundable Items</h2>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Sale Items:</strong> Only <strong>regular-priced items</strong> may be refunded; unfortunately, <strong>sale items</strong> cannot be refunded.</li>
        <li><strong>Gift Cards:</strong> <strong>Gift cards</strong> are non-refundable.</li>
        <li><strong>Personalized Items:</strong> <strong>Custom or personalized items</strong> are final sale and cannot be refunded unless there is a defect in the product.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">How to Request a Refund</h2>
      <ol className="list-decimal pl-5 mb-4">
        <li><strong>Contact Us:</strong> Reach out to us via email to initiate a refund request.</li>
        <li><strong>Provide Details:</strong> Please provide your <strong>order number</strong>, the <strong>items</strong> you wish to return, and the <strong>reason</strong> for the return.</li>
        <li><strong>Return Authorization:</strong> Our team will provide you with a <strong>Return Merchandise Authorization (RMA)</strong> number and detailed instructions on how to return your item(s).</li>
      </ol>

      <h2 className="text-2xl font-semibold mb-2">Return Shipping</h2>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Shipping Costs:</strong> Customers are responsible for paying their own shipping costs for returning their item(s). <strong>Shipping costs are non-refundable.</strong></li>
        <li><strong>Tracking:</strong> We recommend using a <strong>trackable shipping service</strong> or purchasing shipping insurance, as we cannot guarantee that we will receive your returned item.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Processing Refunds</h2>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Inspection:</strong> Once your return is received and inspected, we will send you an email to notify you of the <strong>approval</strong> or <strong>rejection</strong> of your refund.</li>
        <li><strong>Approval:</strong> If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within <strong>5-10 business days</strong>.</li>
        <li><strong>Late or Missing Refunds:</strong> If you haven’t received a refund within <strong>5 business days</strong>, please check your bank account again, then contact your credit card company or bank. If you’ve done all of this and still have not received your refund, please contact us.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Exchanges</h2>
      <p className="mb-4">
        We only replace items if they are <strong>defective</strong> or <strong>damaged</strong>. If you need to exchange an item for the same item, please contact us.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about our refund policy or need further assistance, please do not hesitate to contact us at:
      </p>
      <ul className="list-disc pl-5">
        <li><strong>Instagram & Facebook:</strong> <a href="https://www.instagram.com/yumeatelier.pk" className="text-blue-600">yumeatelier.pk</a></li>
        <li><strong>Email:</strong> <a href="mailto:theyumeatelier@gmail.com" className="text-blue-600">theyumeatelier@gmail.com</a></li>
      </ul>
      <p className="mt-4">
        <strong>Hours:</strong> Monday to Saturday (12:00 PM to 08:00 PM)
      </p>
    </div>
  );
};

export default RefundPolicy;
