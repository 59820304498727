import React from "react";
import { getNewArrivals } from "../../lib/axiosAPI";
import Meta from "../../components/Meta";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsPrimary from "../../components/ProductsPrimary";

export default function NewArrivals() {
  const [newArrivals, setNewArrivals] = React.useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getNewArrivals()
      .then(function (res) {
        setNewArrivals(res.data);
      })
      .catch(function (err) {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="newarrivals">
      <Meta title="New Arrivals" />
      <div className="newarrivals__container">
        <div className="newarrivals__title">
          <h1 className="text-black pt-12">New Arrivals</h1>
        </div>
        <div className="flex justify-center">
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-10 gap-5 pt-4 lg:w-9/12 w-full">
          {newArrivals.map((item) => {
            return <ProductsPrimary key={item._id} product={item} />;
          })}
        </div>
        </div>
      </div>
    </div>
  );
}
