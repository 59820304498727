import React from "react";
import { useDispatch, useSelector } from "react-redux";
import getProductDetail from "../../redux/action/apiProductDetail";
import { useParams } from "react-router-dom";
import Ratings from "../../components/Ratings";
import Meta from "../../components/Meta";
import DisplayPending from "../../components/DisplayPending";
import Alert from "@mui/material/Alert";
import AddCart from "./AddCart";
import SizeSelector from "./SizeSelector"; // New import for size selection
import WriteComments from "./WriteComments";
import SeeComments from "./SeeComments";

export default function ProductPage() {
  const dispatch = useDispatch();
  const params = useParams();

  // Redux
  const productDetail = useSelector((state) => state.productDetail);
  const { pending, error, errorMessage, productInfo } = productDetail;

  const [currImg, setCurrImg] = React.useState(0);
  const [count, setCount] = React.useState(1);
  const [selectedSize, setSelectedSize] = React.useState(""); // New state for selected size

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getProductDetail(params.id, dispatch);
  }, [dispatch, params.id]);

  return (
    <div>
      <Meta title={productInfo.name} />
      {pending ? (
        <DisplayPending pending={pending} />
      ) : error ? (
        <Alert severity="error">{errorMessage}</Alert>
      ) : (
        <div className="productDetailPage">
          <div className="md:w-[400px] w-full h-[600px]">

          <img
            className=" shadow-4D"
            src={productInfo.images?.[currImg]}
            alt="product_images"
          />
          </div>

          <div className="productDetailPage__imgPagination">
            {productInfo.images?.map((img, index) => {
              return (
                <img
                  className={
                    currImg === index
                      ? "productDetailPage__imgPagination--selected"
                      : "productDetailPage__imgPagination--notSelected"
                  }
                  src={img}
                  alt={index}
                  key={index}
                  onClick={(e) => setCurrImg(parseInt(e.target.alt))}
                />
              );
            })}
          </div>

          <div className="product_detail_descriptions pb-12">
            <div className="product_detail_title">{productInfo.name}</div>
            <div className="product_detail_rating">
              <Ratings product={productInfo} />
            </div>

            {/* Size Selector Component */}
            
            {/* Pass selected size to AddCart */}
            <AddCart
              productInfo={productInfo}
              count={count}
              setCount={setCount}
              selectedSize={selectedSize} // New prop for size
            />
          
          { <SizeSelector
              sizes={productInfo.sizes}
              selectedSize={selectedSize}
              setSelectedSize={setSelectedSize}
            />}
            <div className="productDetailPage__description">
              <h1>Fit:</h1>
              <p>{productInfo.fit}</p>
            </div>
            <div className="productDetailPage__description">
              <h1>Composition & Care:</h1>
              <p>{productInfo.description}</p>
            </div>

            {/* <WriteComments productInfo={productInfo} params={params} />
            <SeeComments productInfo={productInfo} /> */}
          </div>
        </div>
      )}
    </div>
  );
}
