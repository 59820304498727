
import React from "react";
import { useDropzone } from "react-dropzone";
import { logout } from "../../redux/action/apiUserAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminCreateProduct } from "../../lib/axiosAPI";
import DisplayPending from "../../components/DisplayPending";
import Alert from "@mui/material/Alert";

export default function AdminCreateProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);

  const [images, setImages] = React.useState([]);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [fit, setFit] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [stock, setStock] = React.useState("");
  const [newArrivals, setNewArrivals] = React.useState(false);
  const [isRecommend, setIsRecommend] = React.useState(false);
  const [sizes, setSizes] = React.useState([{ size: "", quantity: "", price: "", measurements: [{ name: "", value: "" }] }]);
  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState();
  const [createSuccess, setCreateSuccess] = React.useState(false);

  const onDrop = (acceptedFiles) => {
    setImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  const handleSizeChange = (index, field, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[index][field] = value;
    setSizes(updatedSizes);
  };

  const addSizeField = () => {
    setSizes([...sizes, { size: "", quantity: "", price: "", measurements: [{ name: "", value: "" }] }]);
  };

  const removeSizeField = (index) => {
    const updatedSizes = sizes.filter((_, i) => i !== index);
    setSizes(updatedSizes);
  };

  const handleMeasurementChange = (sizeIndex, measurementIndex, field, value) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].measurements[measurementIndex][field] = value;
    setSizes(updatedSizes);
  };

  const addMeasurementField = (sizeIndex) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].measurements.push({ name: "", value: "" });
    setSizes(updatedSizes);
  };

  const removeMeasurementField = (sizeIndex, measurementIndex) => {
    const updatedSizes = [...sizes];
    updatedSizes[sizeIndex].measurements = updatedSizes[sizeIndex].measurements.filter((_, i) => i !== measurementIndex);
    setSizes(updatedSizes);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setPending(true);

    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image);
    });
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("subCategory", subCategory);
    formData.append("brand", brand);
    formData.append("price", price);
    formData.append("countInStock", stock);
    formData.append("newArrivals", newArrivals);
    formData.append("isRecommend", isRecommend);
    formData.append("fit", fit);
    formData.append("sizes", JSON.stringify(sizes));
    
    adminCreateProduct(userInfo, formData)
      .then(function (res) {
        setPending(false);
        setCreateSuccess(true);
      })
      .catch(function (error) {
        setPending(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(error.response.data.message);
        }
        setCreateSuccess(false);
      });
  };

  return (
    <div className="p-6 max-w-3xl mx-auto bg-white rounded-lg shadow-md">
      <DisplayPending pending={pending} />
      <form className="space-y-4" onSubmit={submitHandler}>
        {createSuccess ? (
          <Alert severity="success">Upload Succeeded</Alert>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : null}
        <h2 className="text-2xl font-semibold text-center mb-4">Create New Product</h2>

        <div>
          <label className="block text-sm font-medium">Name</label>
          <input
            type="text"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Description</label>
          <textarea
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Fit</label>
          <textarea
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setFit(e.target.value)}
            value={fit}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Category</label>
          <select
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            required
          >
            <option value="">Select Category</option>
            <option value="men">Men</option>
            <option value="women">Women</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium">Subcategory</label>
          <select
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setSubCategory(e.target.value)}
            value={subCategory}
            required
          >
            <option value="">Select Subcategory</option>
            <option value="pants">Pants</option>
            <option value="shirts">Shirts</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium">Brand</label>
          <input
            type="text"
            className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onChange={(e) => setBrand(e.target.value)}
            value={brand}
            required
          />
        </div>

        <div className="flex gap-4">
          <div className="flex-1">
            <label className="block text-sm font-medium">Price (In Cent)</label>
            <input
              type="number"
              className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              min="0"
              required
            />
          </div>

          <div className="flex-1">
            <label className="block text-sm font-medium">Count In Stock</label>
            <input
              type="number"
              className="w-full mt-1 p-2 border bg-white border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={(e) => setStock(e.target.value)}
              value={stock}
              min="0"
              required
            />
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={newArrivals}
            onChange={(e) => setNewArrivals(!newArrivals)}
          />
          <label>New Arrivals?</label>
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={isRecommend}
            onChange={(e) => setIsRecommend(!isRecommend)}
          />
          <label>Recommend?</label>
        </div>

        {/* Image upload */}
        <div>
          <label className="block text-sm font-medium mb-2">Product Images</label>
          <div
            {...getRootProps({
              className:
                "border-dashed border-2 border-gray-300 rounded-lg p-4 text-center cursor-pointer",
            })}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-2">
          <label className="block text-sm font-medium mb-2">Sizes</label>
            {images.map((file, index) => (
              <div key={file.preview} className="border p-2 rounded-lg">
                <img
                  src={file.preview}
                  alt="preview"
                  className="h-24 w-full object-cover rounded-md"
                />
                <button
                  className="mt-2 w-full text-sm text-red-500"
                  onClick={() => setImages(images.filter((_, i) => i !== index))}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Sizes and Measurements */}
        <div className="space-y-4">
          {sizes.map((sizeObj, index) => (
            <div key={index} className="space-y-2">
              <div className="flex space-x-2 flex-wrap">
                <input
                  type="text"
                  placeholder="Size"
                  className="flex-1 p-2 border rounded-md bg-white"
                  onChange={(e) => handleSizeChange(index, "size", e.target.value)}
                  value={sizeObj.size}
                  required
                />
                <input
                  type="number"
                  placeholder="Quantity"
                  className="flex-1 p-2 border rounded-md bg-white"
                  onChange={(e) => handleSizeChange(index, "quantity", e.target.value)}
                  value={sizeObj.quantity}
                  min="0"
                  required
                />
                <input
                  type="number"
                  placeholder="Price"
                  className="flex-1 p-2 border rounded-md bg-white"
                  onChange={(e) => handleSizeChange(index, "price", e.target.value)}
                  value={sizeObj.price}
                  min="0"
                  required
                />
                <button
                  type="button"
                  className="text-white bg-red-400 px-4 rounded-lg py-2 mt-3"
                  onClick={() => removeSizeField(index)}
                >
                  Remove Size
                </button>
              </div>
              {sizeObj.measurements.map((measurement, measurementIndex) => (
                <div key={measurementIndex} className="flex flex-wrap space-x-2">
                  <input
                    type="text"
                    placeholder="Measurement Name"
                    className="flex-1 p-2 border rounded-md bg-white"
                    onChange={(e) =>
                      handleMeasurementChange(index, measurementIndex, "name", e.target.value)
                    }
                    value={measurement.name}
                  />
                  <input
                    type="text"
                    placeholder="Measurement Value"
                    className="flex-1 p-2 border rounded-md bg-white"
                    onChange={(e) =>
                      handleMeasurementChange(index, measurementIndex, "value", e.target.value)
                    }
                    value={measurement.value}
                  />
                  <button
                    type="button"
                    className="text-white bg-red-400 px-4 rounded-lg "
                    onClick={() => removeMeasurementField(index, measurementIndex)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="text-blue-500 border border-blue-500 px-4 py-2 rounded-lg mt-2"
                onClick={() => addMeasurementField(index)}
              >
                Add Measurement
              </button>
            </div>
          ))}
          <button
            type="button"
            className="text-blue-500 border border-blue-500  px-4 py-2 rounded-lg"
            onClick={addSizeField}
          >
            Add Size
          </button>
        </div>

        <button
          type="submit"
          className="w-full py-2 mt-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          Create Product
        </button>
        <button
            className="auth-button"
            type="button"
            onClick={() => navigate("/admin/productlist")}
          >
            Go Back
          </button>
      </form>
    </div>
  );
}


