import React from "react";
// import "daisyui/dist/full.css";
import ShopByRoom from "./ShopRoom";
import HeroSection from "../../assests/img5.png"
import BestSellingProducts from "./BestSelling";
import OurCreations from "./Creations";
import AboutUs from "./About";
import Review from "./Review"
import { useDispatch, useSelector } from "react-redux";
import { getProductList } from "../../redux/action/apiProductList.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const FurniturePage = () => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search");
  const currPageQuery = searchParams.get("currPage");
  const sortQuery = searchParams.get("sortBy");
  const inStockQuery = searchParams.get("inStock");
  const categoryQuery = searchParams.get("category");
  const brandQuery = searchParams.get("brand");
  const minPriceQuery = searchParams.get("minPrice");
  const maxPriceQuery = searchParams.get("maxPrice");
  const { productListInfo } = productList;
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/newarrivals`);
  };
  React.useEffect(() => {
    getProductList(
      dispatch,
      searchQuery,
      currPageQuery,
      sortQuery,
      inStockQuery,
      categoryQuery,
      brandQuery,
      minPriceQuery,
      maxPriceQuery
    );
  }, [
    dispatch,
    searchQuery,
    currPageQuery,
    sortQuery,
    inStockQuery,
    categoryQuery,
    brandQuery,
    minPriceQuery,
    maxPriceQuery,
  ]);
  return (
    <div className=" text-white min-h-screen">
      {/* Header Section */}
      {/* <header className="flex justify-between items-center p-6">
        <div className="text-2xl font-bold">Furniture</div>
        <nav>
          <ul className="flex space-x-6">
            <li className="hover:underline">Home</li>
            <li className="hover:underline">Furniture</li>
            <li className="hover:underline">Accessories</li>
            <li className="hover:underline">About us</li>
          </ul>
        </nav>
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="input input-bordered input-sm"
          />
        </div>
      </header> */}

      {/* Main Section */}
      <main className="relative flex h-[80vh] items-center lg:justify-end justify-start">
        {/* Left Side Content */}
        <img
          src={HeroSection}
          alt="hero"
          className="absolute h-full w-full object-cover object-top top-0 left-0"
        />
        <div className="lg:w-1/2 w-full p-12 z-[999] relative">
          <h1 className="lg:text-5xl text-2xl font-bold mb-8 lg:text-white text-black">
            Elevate Your Style with Trendy & Comfortable Fashion
          </h1>
          <p className="lg:text-lg text-sm mb-6 lg:text-white text-gray-100">
            Discover our latest collection of stylish and comfortable clothing, perfect for any occasion.
            From casual wear to elegant outfits, we bring you the best fashion trends at unbeatable prices.
          </p>
          <a onClick={handleSearch} className="lg:border border-none cursor-pointer border-black lg:bg-transparent bg-black text-blact px-12 py-2 text-lg rounded-lg lg:text-black text-white" >
            Shop Now
          </a>
        </div>

        {/* Right Side - Featured Carousel */}
        {/* 
  <div className="w-1/2 flex items-center justify-end z-[999999]">
    <div className="carousel carousel-vertical w-48 h-72 bg-transparent p-6 rounded-lg">
      <div className="carousel-item flex flex-col items-center mb-4">
        <img
          src="https://via.placeholder.com/150"
          alt="Black Sofa"
          className="w-24 h-24"
        />
        <h3 className="text-xl mt-2">Black Sofa</h3>
        <p>$250</p>
      </div>
      <div className="carousel-item flex flex-col items-center">
        <img
          src="https://via.placeholder.com/100"
          alt="Black Chair"
          className="w-24 h-24"
        />
        <h3 className="text-xl mt-2">Black Chair</h3>
        <p>$100</p>
      </div>
    </div>
  </div>
  */}
      </main>

      {/* Why Choosing Us Section */}

      <div className="w-full gap-6">
        <div className="flex items-center justify-center space-x-4 py-3 bg-black text-white shadow-lg rounded-b-[100%]">

          <div>
            <p className="text-sm text-white">Imported from Japan</p>
          </div>
        </div>

      </div>


      {/* <ShopByRoom/> */}
      {/* <BestSellingProducts products={productListInfo?.products} /> */}
      <OurCreations />
      {/* <AboutUs firstImage /> */}
      {/* <Review /> */}
    </div>
  );
};

export default FurniturePage;
